export const SlideShow = [
  // {
  //   id: 1,
  //   imgPath:
  //     // `${process.env.PUBLIC_URL}/assets/sl1.jpg`,
  //     `${process.env.PUBLIC_URL}/assets/rakuchiad.jpg`,
  // },
  {
    id: 2,
    imgPath: `${process.env.PUBLIC_URL}/assets/sl1.jpg`,
  },
  // {
  //   id: 3,
  //   imgPath:
  //   `${process.env.PUBLIC_URL}/assets/rakuchiad2.jpg`
  //     // "https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622021.jpg",
  //   },
  {
    id: 4,
    imgPath: `${process.env.PUBLIC_URL}/assets/sl2.jpg`,
  },
  // {
  //   id: 5,
  //   imgPath:
  //   `${process.env.PUBLIC_URL}/assets/rakuchiad3.jpg`
  // },
  {
    id: 6,
    imgPath: `${process.env.PUBLIC_URL}/assets/sl3.jpg`,
  },
];
